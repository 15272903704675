<template>
  <li class="result__item">
    <a
      :class="[
      'result__link',
      { 'is-cta-association': isCtaAssociation }
      ]"
      :href="itemUrl"
      :target="item.target ? item.target : null"
      :rel="item.target ? 'noreferrer noopener' : null"
      @click.prevent="trackCall"
    >
    <template v-if="isCtaAssociation">
      <font-awesome-icon
        v-if="item.icon"
        class="result__icon"
        :icon="item.icon"
        aria-hidden="true"
      />
    </template>
    <template v-if="isAssociation">
      <figure v-if="item.logo">
        <img
          class="result__logo"
          :src="item.logo"
          alt=""
          role="presentation"
        >
      </figure>
      <div v-else class="result__logo result__logo--empty">
        {{ title[0] }}
      </div>
    </template>
    <span class="result__title">{{ title }}</span>
    <template v-if="!isAssociation && !isCtaAssociation">
      <span v-if="item.target" class="visually-hidden">
        {{ $t('haComponents.haHeaderPublic.accessibility.openNewWindow') }}
      </span>
      <font-awesome-icon
        v-if="item.icon"
        class="result__icon"
        :icon="item.icon"
        aria-hidden="true"
      />
    </template>
    </a>
  </li>
</template>

<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { computed } from 'vue'
import useHeaderPublic from '../../useHeaderPublic'

const props = defineProps({
  item: {},
  isAssociation: false,
  isCtaAssociation: false
})

const { segment } = useHeaderPublic()

const trackCall = () => {
  const trackInfo = {
    item_type: 'Drop down item',
    item_label : props.isAssociation ? 'BO asso' : title.value,
    href: itemUrl.value
  }

  if (segment.value) {
    segment.value.track('Header Item Clicked', trackInfo)
  }

  if (props.item?.target) {
    window.open(`${itemUrl.value}`, '_blank', 'noopener, noreferrer')
  } else {
    const baseUrl = window.location.origin
    const isFullURL = itemUrl.value.startsWith('https')
    window.location = isFullURL ? itemUrl.value : `${baseUrl}${itemUrl.value}`
  }
}

const title = computed(() => {
  return props.item.label || props.item.name
})

const itemUrl = computed(() => {
  return props.item.href || props.item.url
})

</script>

<style lang="scss" scoped>
  @import './headerListItem.scss';
</style>
