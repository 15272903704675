<script setup>
import { ref } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
const props = defineProps({
  label: String,
  placeholder: String,
  inputClass: String,
  model: String,
  canClear: Boolean
})

const input = ref(null)
const emit = defineEmits(['on-search', 'handle-input', 'clear'])

const selectInput = () => {
  input.value.focus()
  emit('handle-input')
}
</script>

<template>
  <div :class="['form__inputs', `form__inputs--${inputClass}`]" @click.prevent="selectInput">
    <label :for="`query-${inputClass}`" class="form__label">
      {{ label }}
    </label>
    <div class="form__input">
      <input
        ref="input"
        type="text"
        :name="`query-${inputClass}`"
        :id="`query-${inputClass}`"
        autocomplete="off"
        aria-autocomplete="list"
        :data-test="`input--${inputClass}`"
        :placeholder="placeholder"
        :value="model"
        @input="$emit('on-search', $event)"
      />

      <button
        v-if="canClear"
        id="clearSearchInputButton"
        type="button"
        class="form__input__clear"
        @click="$emit('clear')"
      >
        <font-awesome-icon :icon="faTimes" aria-hidden="true" />
      </button>
    </div>
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
@import './searchInputContainer.scss';
</style>
