<script setup>
import { ref, onBeforeUnmount, onMounted } from 'vue'
import HaSliderGUI from '../HaSliderGUI/HaSliderGUI.vue'

const props = defineProps({
  images: {
    type: Array,
    required: true,
    validator: v => v.length === 3
  },
  animationInterval: {
    type: Number,
    default: 5000
  }
})

let carouselInterval
const activeIndex = ref(0)

const onSelectIndex = async DOMIndex => {
  activeIndex.value = DOMIndex
  initCarouselInterval()
}

const onCarouselTick = () => {
  activeIndex.value = activeIndex.value >= props.images.length - 1 ? 0 : activeIndex.value + 1
}

const initCarouselInterval = () => {
  if (carouselInterval) clearInterval(carouselInterval)
  carouselInterval = setInterval(onCarouselTick, props.animationInterval)
}

onMounted(initCarouselInterval)
onBeforeUnmount(() => clearInterval(carouselInterval))
</script>

<template>
  <div data-test="triptych-carousel">
    <div class="carousel">
      <ul>
        <li
          v-for="({ url, label, alt }, index) in props.images"
          :key="index"
          data-test="carousel-img"
          :class="['carousel__card', { active: activeIndex === index }]"
          @click="onSelectIndex(index)"
        >
          <p class="carousel__card__label">
            <slot name="card-label" :value="label" />
          </p>

          <img class="carousel__card__image" :src="url" :alt="alt" />
        </li>
      </ul>
    </div>

    <HaSliderGUI
      class="default-slider"
      :current="activeIndex + 1"
      :length="images.length"
      @change="onSelectIndex($event)"
    />
  </div>
</template>

<style lang="scss" scoped>
.carousel {
  position: relative;
  width: 100%;
  height: $ha-unit * 57.5;
  margin-bottom: $ha-spacing-large;
  overflow: hidden;
  cursor: pointer;

  &:has(.carousel__card:nth-of-type(1).active) .carousel__card:nth-of-type(2),
  &:has(.carousel__card:nth-of-type(2).active) .carousel__card:nth-of-type(3),
  &:has(.carousel__card:nth-of-type(3).active) .carousel__card:nth-of-type(1) {
    left: 0;
  }

  &:has(.carousel__card:nth-of-type(1).active) .carousel__card:nth-of-type(3),
  &:has(.carousel__card:nth-of-type(2).active) .carousel__card:nth-of-type(1),
  &:has(.carousel__card:nth-of-type(3).active) .carousel__card:nth-of-type(2) {
    left: 100%;
  }

  &__card {
    position: absolute;
    top: 0;
    display: grid;
    align-items: center;
    width: $ha-unit * 37.5; // 300px
    height: $ha-unit * 57.5; // 460px
    padding: $ha-spacing-medium $ha-spacing-big $ha-spacing-big $ha-spacing-big;
    text-align: center;
    background: var(--ha-color-white);
    border-radius: $ha-unit * 5; // 40px
    transition: all ease-in-out 0.5s;

    img {
      filter: drop-shadow(calc($ha-unit / 2) calc($ha-unit / 2) calc($ha-unit * 2) #2e2f5e26);
    }

    &:not(.active) {
      z-index: 1;
      transform: translate(calc(-22% + -50%));
      scale: 0.7;
    }

    &.active {
      left: 50%;
      z-index: 2;
      transform: translate(-50%);
      scale: 1;
    }

    &__label {
      margin-bottom: $ha-spacing-small;
      color: var(--ha-color-midnight);
      font-weight: $ha-font-weight-semibold;
      font-size: $ha-font-size-regular;
      line-height: $ha-unit * 2.7;

      :deep(b) {
        color: var(--ha-color-iris);
      }
    }
  }
}

.default-slider {
  width: max-content;
  margin: auto;
}
</style>
