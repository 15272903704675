<template>
  <button :class="['searchBtn', { 'searchBtn--open': isOpenSearch }]" @click="openSearch">
    <span :class="['searchBtn__fakeInput', { 'searchBtn__fakeInput--open': isOpenSearch }]">
      <span v-if="query || city">
        <span v-if="query" class="searchBtn__query">{{ query }}</span>
        <span v-if="city" class="searchBtn__query">{{ city }}</span>
      </span>
      <span v-else>
        <span class="visually-hidden"> {{ $t('haComponents.haHeaderPublic.search.btn') }} </span>
        {{ $t('haComponents.haHeaderPublic.search.input') }}
      </span>
    </span>
    <span class="searchBtn__fakeBtn">
      <font-awesome-icon class="icon" :icon="faSearch" aria-hidden="true" />
      <span> {{ $t('haComponents.haHeaderPublic.search.btn') }} </span>
    </span>
  </button>
</template>

<script setup>
import HaButton from '../../../../HaButton/HaButton.vue'
import useHeaderPublic from '../../../useHeaderPublic'
import useHeaderSearch from '../useHeaderSearch'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSearch } from '@fortawesome/pro-solid-svg-icons'

const { isOpenSearch, toggleIsOpenSearch, isOpenMenu, segment } = useHeaderPublic()
const { city, query } = useHeaderSearch()

const openSearch = () => {
  if (isOpenMenu.value) {
    isOpenMenu.value = !isOpenMenu.value
  }
  toggleIsOpenSearch()
  const trackInfo = {
    item_type: 'Button',
    item_label: 'Rechercher'
  }

  segment.value.track('Header Item Clicked', trackInfo)
}
</script>

<style lang="scss" scoped>
@import './searchBtn.scss';
</style>
