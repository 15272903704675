<template>
  <li class="result__element">
    <button
      type="button"
      class="result__item result__item__btn"
      @click.stop.prevent="emit('select-result', selectedCity)"
    >
      <font-awesome-icon
        class="result__icon"
        :icon="icon === 'location' ? faMapMarkerAlt : faHistory"
        fixed-width
      />
      <div class="result__city">
        <!-- eslint-disable vue/no-v-html -->
        <span class="city__title" v-html="title"></span>
        <!-- eslint-enable vue/no-v-html -->
        <span class="city__subtitle">
          {{ subtitleInfos }}
        </span>
      </div>
    </button>
  </li>
</template>

<script setup>
import { computed } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faMapMarkerAlt, faHistory } from '@fortawesome/pro-solid-svg-icons'
const props = defineProps({
  icon: String,
  title: String,
  item: {
    type: Object,
    required: false
  }
})
const emit = defineEmits(['select-result'])

const subtitleInfos = computed(() => {
  if (props.item.zipcode) {
    return `${props.item.zipcode}, ${props.item.place_department}, ${props.item.country}`
  }
  return `${props.item.place_department}, ${props.item.country}`
})
const selectedCity = {
  ...props.item
}
</script>

<style lang="scss" scoped>
.result {
  &__item {
    display: inline-flex;
    align-items: center;
    width: 100%;
    padding: $ha-spacing-tiny;
    color: var(--ha-color-text);
    font-size: $ha-font-size-small;
    cursor: pointer;

    &:hover {
      background-color: var(--ha-color-background);
    }

    &:focus {
      outline: none;
      box-shadow: inset 0 0 0 ($ha-unit * 0.25) var(--ha-color-border); // 0 0 0 2px
    }
  }

  &__icon {
    margin-right: $ha-spacing-small;
    color: var(--ha-color-primary);
  }

  &__city {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
}
</style>
