<template>
  <div class="dropdown">
    <div class="dropdown__extraBtn">
      <slot name="button" />
    </div>
    <button type="button" class="dropdown__title">
      <figure v-if="dropdownImage" class="dropdown__image">
        <img :src="dropdownImage" />
      </figure>
      <div
        v-else-if="isUserDropdown && userFirstChar"
        class="dropdown__image dropdown__image--fallback"
      >
        {{ userFirstChar }}
      </div>
      <span>
        <slot name="title" />
      </span>
      <font-awesome-icon :icon="faChevronDown" aria-hidden="true" />
    </button>
    <div class="dropdown__list-wrapper__outer">
      <ul
        :class="['dropdown__list-wrapper__inner', { delegatePadding }]"
        data-test="header-content-dropdown"
      >
        <slot name="content" />
        <li class="cta__wrapper">
          <slot name="cta" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons'
import useHeaderPublic from '../../useHeaderPublic'
import { computed, ref } from 'vue'

const props = defineProps({
  isOpenMenu: true,
  delegatePadding: false,
  dropdownImage: null,
  isUserDropdown: false
})

const { user } = useHeaderPublic()
const userFirstChar = computed(() => user.value?.firstName?.[0])
</script>

<style lang="scss" scoped>
@import './dropdown.scss';
</style>
