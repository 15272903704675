<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import HaDropdown from '../HaDropdown/HaDropdown'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons'
import { computed, ref } from 'vue'

const props = defineProps({
  steps: { type: Array, required: true }, // [{header?: string, text: string, isComplete: boolean, isDisabled: boolean, hasUpdateMarker: boolean}]
  selectedIndex: { type: Number, default: 0 },
  horizontalBorders: Boolean
})

const emit = defineEmits(['update:selected-index'])

const focusedStepIndex = ref(null)
const refDropdown = ref(null)

const selectedStep = computed(() => props.steps[props.selectedIndex])

const onStepClick = index => {
  if (index === props.selectedIndex) return
  emit('update:selected-index', index)
  refDropdown.value.closeDropdown()
}
const onFocus = index => {
  focusedStepIndex.value = index
}
</script>

<template>
  <div>
    <HaDropdown
      ref="refDropdown"
      class="dropdown"
      :class="{ 'dropdown--horizontal-borders': horizontalBorders }"
      data-test="breadcrumbs"
    >
      <template #trigger>
        <button class="dropdown__trigger" data-test="dropdown-trigger">
          <div class="step__content step__content--flex">
            <div>
              <p v-if="selectedStep.header" class="step__content__header">
                {{ selectedStep.header }}
              </p>
              <p
                class="step__content__body"
                :class="{ 'update-marker': selectedStep.hasUpdateMarker }"
              >
                {{ selectedStep.text }}
              </p>
            </div>

            <FontAwesomeIcon :icon="faChevronDown" aria-hidden="true" />
          </div>
        </button>
      </template>
      <template #content>
        <ol>
          <li
            v-for="({ header, text, isDisabled, hasUpdateMarker }, index) in props.steps"
            :key="index"
            :class="[
              'dropdown__item',
              'step',
              {
                'step--disabled': isDisabled,
                'step--active': index === selectedIndex,
                'step--adjacent-to-active': index === selectedIndex - 1,
                'step--precedes-active': index < selectedIndex
              }
            ]"
            :data-test="`dropdown-step-${index}`"
          >
            <button :disabled="isDisabled" class="step__content" @click="onStepClick(index)">
              <p v-if="header" class="step__content__header">
                {{ header }}
              </p>
              <p :class="[{ 'update-marker': hasUpdateMarker }, 'step__content__body']">
                {{ text }}
              </p>
            </button>
          </li>
        </ol>
      </template>
    </HaDropdown>

    <ol class="breadcrumbs" data-test="breadcrumbs">
      <li
        v-for="({ header, text, isDisabled, hasUpdateMarker }, index) in props.steps"
        :key="index"
      >
        <button
          class="step"
          :class="{
            'step--disabled': isDisabled,
            'step--active': selectedIndex === index,
            'step--adjacent-to-active': [focusedStepIndex, selectedIndex].includes(index + 1),
            'step--precedes-active': index < selectedIndex
          }"
          :disabled="isDisabled"
          :data-test="`breadcrumbs-step-${index}`"
          @focus="onFocus(index)"
          @focusout="onFocus(null)"
          @click="onStepClick(index)"
        >
          <div class="step__content">
            <p v-if="header" class="step__content__header">
              {{ header }}
            </p>
            <p class="step__content__body" :class="{ 'update-marker': hasUpdateMarker }">
              {{ text }}
            </p>
          </div>
          <svg v-if="index < steps.length - 1" viewBox="0 0 100 100" preserveAspectRatio="none">
            <path d="M0 0 L 99 50 L 0 100" />
          </svg>
        </button>
      </li>
    </ol>
  </div>
</template>
<style scoped lang="scss">
$border-regular: solid var(--ha-color-border) $ha-border-width-regular;
$border-highlight: solid var(--ha-color-primary) $ha-border-width-large;

@include mediaQuery(1200, 'max') {
  .dropdown {
    display: block;
  }

  .breadcrumbs {
    display: none;
  }
}

@include mediaQuery(1200) {
  .dropdown {
    display: none;
  }

  .breadcrumbs {
    display: flex;
    flex-flow: row nowrap;
  }
}

p {
  color: var(--ha-color-text);
}

button {
  cursor: pointer;
}

.step {
  &__content {
    width: 100%;
    height: 100%;
    padding: $ha-spacing-small;
    color: var(--ha-color-text);
    text-align: left;
    background: var(--ha-color-white);

    &__header {
      margin-bottom: $ha-spacing-mini;
      font-size: $ha-font-size-small;
      line-height: $ha-font-size-small;
    }

    &__body {
      display: flex;
      margin: 0;

      &.update-marker::after {
        align-self: center;
        width: $ha-unit;
        height: $ha-unit;
        margin-left: $ha-spacing-mini;
        background-color: var(--ha-color-info);
        border-radius: 100%;
        content: '';
      }
    }
  }

  &--active {
    .step__content {
      background: var(--ha-color-primary-lighten);
    }

    .step__content__body {
      font-weight: $ha-font-weight-bold;
    }
  }

  &--disabled {
    cursor: not-allowed;

    .step__content {
      background-color: var(--ha-color-background-disabled);

      p {
        color: var(--ha-color-text-light);
      }

      &__body {
        font-weight: $ha-font-weight-regular;
      }
    }
  }

  &--precedes-active {
    font-weight: $ha-font-weight-semibold;
  }
}

.dropdown {
  position: relative;

  &--horizontal-borders {
    border-right: $border-regular;
    border-left: $border-regular;
  }

  &__trigger {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: var(--ha-color-white);
    border-top: $border-regular;
    border-bottom: $border-regular;

    .step__content__body {
      font-weight: $ha-font-weight-bold;
    }
  }

  &__item {
    border-bottom: $border-regular;
  }

  .step {
    &__content {
      &--flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    &--active,
    &--precedes-active,
    &:has(> button:focus) {
      border-left: $border-highlight;
    }
  }

  :deep(.HaDropdown--Wrapper) {
    width: 100%;
    border-radius: 0;
    transform: translateY(0);
  }
}

.breadcrumbs {
  li {
    flex: 1 1 0;
    margin-right: calc($ha-unit * -4 + $ha-unit / 10); // -svgWidth + Magic number

    &:first-of-type {
      .step__content {
        padding-left: $ha-spacing-medium;
        border-left: $border-regular;
        border-top-left-radius: $ha-radius-regular;
        border-bottom-left-radius: $ha-radius-regular;
      }

      .step--active,
      .step:focus {
        .step__content {
          border-left: $border-highlight;
        }
      }
    }

    &:last-of-type {
      margin-right: 0;

      .step__content {
        width: 100%;
        border-right: $border-regular;
        border-top-right-radius: $ha-radius-regular;
        border-bottom-right-radius: $ha-radius-regular;
      }

      .step--active,
      .step:focus {
        .step__content {
          border-right: $border-highlight;
          border-top-left-radius: $ha-radius-regular;
          border-bottom-left-radius: $ha-radius-regular;
        }
      }
    }

    .step {
      position: relative;
      display: flex;
      flex: 1;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 0;

      &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: $ha-unit * 4;
        padding-left: calc($ha-unit * 4 + $ha-spacing-big);
        text-align: left;
        border-top: $border-regular;
        border-bottom: $border-regular;
      }

      svg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        width: $ha-unit * 4;
        height: 100%;
        fill: var(--ha-color-white);
        stroke: var(--ha-color-border);
        stroke-width: $ha-border-width-regular * 2; // Magic number
      }

      &--active,
      &:focus {
        .step__content {
          border-top: $border-highlight;
          border-bottom: $border-highlight;
        }

        svg {
          stroke: var(--ha-color-primary);
          stroke-width: $ha-border-width-large * 2; // Magic number
        }
      }

      &--active {
        svg {
          fill: var(--ha-color-primary-lighten);
        }
      }

      &--adjacent-to-active {
        svg {
          stroke: var(--ha-color-primary);
          stroke-width: $ha-border-width-large * 2; // Magic number
        }
      }

      &--precedes-active {
        .step__content {
          border-bottom: $border-highlight;
        }
      }

      &--disabled {
        svg {
          fill: var(--ha-color-background-disabled);
        }
      }
      // Prevent the focus ring border-right to be cropped
      &:focus-visible {
        position: relative;
      }
    }
  }
}
</style>
