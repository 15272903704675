<script setup>
import { ref, computed, watch } from 'vue'
import HaInputGroup from '../HaInputGroup/HaInputGroup.vue'
import HaNumericInput from '../HaNumericInput/HaNumericInput.vue'
import constants from './constants.js'

const props = defineProps({
  value: [String, Number],
  placeholder: {
    type: String,
    default: '--.--'
  },
  isValid: {
    type: Boolean,
    default: null
  },
  disabled: Boolean,
  size: {
    type: String,
    default: 'medium',
    validator: value => constants.sizes.includes(value)
  },
  unit: {
    type: String,
    default: 'price',
    validator: value => constants.units.includes(value)
  }
})

const emits = defineEmits(['input'])

const getParseValue = value => {
  if ((!value && value !== 0) || isNaN(value)) return null
  const parseValue = String(value).replace(',', '.')
  return Number.parseFloat(parseValue)
}

const inputValue = ref(getParseValue(props.value))

const classNameSize = computed(() => `ha-input-amount--${props.size}`)
const classNameValid = computed(() => (props.isValid === false ? 'ha-input-amount--invalid' : ''))

const decimal = computed(() => (props.unit === 'percentage' ? 0 : 2))
const max = computed(() => (props.unit === 'percentage' ? 100 : 99999.99))

const onUpdate = value => {
  if (value === '') {
    emits('input', null)
    return
  }
  const parseValue = getParseValue(value)
  const lockedValue = Math.max(0, getParseValue(value))
  if (parseValue < 0) inputValue.value = 0
  emits('input', lockedValue)
}

watch(
  () => props.value,
  () => {
    const parseValue = getParseValue(props.value)
    if (parseValue === getParseValue(inputValue.value)) return
    inputValue.value = parseValue
  },
  { immediate: true }
)
</script>

<template>
  <HaInputGroup
    class="ha-input-amount"
    :class="[classNameSize, classNameValid]"
    data-test="ha-input-amount"
  >
    <HaNumericInput
      v-model="inputValue"
      :placeholder="placeholder"
      :is-valid="isValid"
      :disabled="disabled"
      :size="size"
      :decimal="decimal"
      :max="max"
      :min="0"
      :canBeNegative="false"
      :aria-label="$t(`haComponents.haInputAmount.label.${unit}`)"
      data-test="ha-input-amount-input"
      @input="onUpdate"
      v-on="$listeners"
    />
    <p class="ha-input-amount__unit" data-test="ha-input-amount-unit">
      {{ $t(`haComponents.haInputAmount.${unit}`) }}
    </p>
  </HaInputGroup>
</template>

<style lang="scss" scoped>
@import './HaInputAmount';
</style>
