<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import getDataError from '../../helpers/getDataError'

const emits = defineEmits(['input'])
const props = defineProps({
  options: {
    type: Array,
    default: () => []
  },
  value: {
    type: String
  },
  label: {
    type: String,
    default: ''
  },
  isValid: {
    type: Boolean,
    default: null
  }
})

const handleInputChange = event => {
  emits('input', event.target.value)
}
</script>

<template>
  <div class="ha-group-radio" data-test="ha-group-radio" :data-error="getDataError(isValid)">
    <div class="ha-group-radio-label">{{ label }}</div>
    <div class="ha-group-radio-container">
      <div
        v-for="option in options"
        :key="option.id"
        class="ha-group-radio-input"
        :class="{ active: option.id === value, disabled: option.disabled }"
      >
        <input
          :id="option.id"
          v-model="value"
          type="radio"
          :value="option.id"
          :name="option.id"
          :disabled="option.disabled"
          data-test="ha-group-radio-input"
          @click="handleInputChange"
          @keyup.enter="handleInputChange"
        />

        <label :for="option.id">
          <FontAwesomeIcon v-if="option.icon" class="icon" :icon="option.icon" />
          <span>{{ option.label }}</span>
        </label>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.ha-group-radio {
  width: 100%;
  font-size: $ha-font-size-small;

  &-label {
    margin-bottom: $ha-spacing-tiny;
    color: #717198;
    font-weight: 600;
    font-size: $ha-font-size-small;
  }

  &-container {
    display: flex;
  }

  &-container .ha-group-radio-input:first-child {
    border-top-left-radius: $ha-radius-regular;
    border-bottom-left-radius: $ha-radius-regular;
  }

  &-container .ha-group-radio-input:last-child {
    border-top-right-radius: $ha-radius-regular;
    border-bottom-right-radius: $ha-radius-regular;
  }

  &-input {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    border: $ha-border-width-regular solid #d1d6de;

    input[type='radio'] {
      position: absolute;
      top: 0;
      opacity: 0.0001;

      &:focus + label {
        border-color: var(--ha-color-primary);
        outline: none;
        @include focus;
      }
    }

    label {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: $ha-spacing-small;
      color: #2e2f5e;
      font-weight: 400;
      text-align: center;
      cursor: pointer;

      .icon {
        margin-bottom: $ha-spacing-tiny;
        color: #717198;
      }
    }
  }

  & .disabled {
    background-color: var(--ha-color-background-disabled);
    border-color: var(--ha-color-border);

    label {
      cursor: not-allowed;
    }
  }

  & .active {
    background: #edfbf4;
    border: $ha-border-width-large solid #49d38a;

    label {
      font-weight: 700;

      .icon {
        color: #2e2f5e;
        font-weight: 900;
      }
    }
  }
}
</style>
