<script setup>
import { computed, useSlots, onBeforeMount, onBeforeUnmount } from 'vue'
import { SIDE_PANEL_PLACEMENT, SIDE_PANEL_SIZE } from './constants'
import { useScrollLocker } from '../../composables/useScrollLocker'
import { functions } from '@ha/helpers'

const props = defineProps({
  placement: {
    type: String,
    default: SIDE_PANEL_PLACEMENT.RIGHT,
    validator(placement) {
      return Object.values(SIDE_PANEL_PLACEMENT).includes(placement)
    }
  },
  size: {
    type: String,
    default: 'default',
    validator(size) {
      return Object.keys(SIDE_PANEL_SIZE).includes(size.toString().toUpperCase())
    }
  }
})

const emits = defineEmits(['close'])

const slots = useSlots()
const uniqueId = functions.generateRandomId()

const hasHeader = computed(() => !!slots.header)
const hasFooter = computed(() => !!slots.footer)
const sidePanelClasses = computed(() => `ha-side-panel-${props.placement}`)
const sidePanelWrapperClasses = computed(
  () => `ha-side-panel-wrapper-${props.placement} ha-side-panel-wrapper-${props.size.toLowerCase()}`
)

const closeOnEscape = e => {
  if (e.key === 'Escape') emits('close')
}

const { lock, unlock } = useScrollLocker(uniqueId)

onBeforeMount(() => {
  document.addEventListener('keydown', closeOnEscape)
  lock()
})

onBeforeUnmount(() => {
  document.removeEventListener('keydown', closeOnEscape)
  unlock()
})
</script>

<template>
  <transition name="ha-side-panel-animation" appear>
    <div class="ha-side-panel" :class="sidePanelClasses" data-test="ha-side-panel">
      <div class="ha-side-panel-mask" data-test="ha-side-panel-mask" />
      <div
        class="ha-side-panel-wrapper"
        :class="sidePanelWrapperClasses"
        data-test="ha-side-panel-wrapper"
      >
        <div class="ha-side-panel-wrapper-content">
          <div v-if="hasHeader" class="ha-side-panel-header">
            <div class="ha-side-panel-header-title">
              <slot name="header"></slot>
            </div>
          </div>
          <div class="ha-side-panel-body">
            <slot />
          </div>
          <div v-if="hasFooter" class="ha-side-panel-footer">
            <slot name="footer" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
$shadow-up: 0 (-$ha-spacing-mini) $ha-spacing-medium (-$ha-spacing-tiny) rgba(0, 0, 0, 0.08),
  0 (-$ha-spacing-tiny) $ha-spacing-large 0 rgba(0, 0, 0, 0.05),
  0 (-$ha-spacing-small) $ha-spacing-jumbo $ha-spacing-medium rgba(0, 0, 0, 0.03);
$shadow-down: 0 $ha-spacing-mini $ha-spacing-medium (-$ha-spacing-tiny) rgba(0, 0, 0, 0.08),
  0 $ha-spacing-tiny $ha-spacing-large 0 rgba(0, 0, 0, 0.05),
  0 $ha-spacing-small $ha-spacing-jumbo $ha-spacing-medium rgba(0, 0, 0, 0.03);
$shadow-left: (-$ha-spacing-mini) 0 $ha-spacing-medium (-$ha-spacing-tiny) rgba(0, 0, 0, 0.08),
  (-$ha-spacing-tiny) 0 $ha-spacing-large 0 rgba(0, 0, 0, 0.05),
  (-$ha-spacing-small) 0 $ha-spacing-jumbo $ha-spacing-medium rgba(0, 0, 0, 0.03);
$shadow-right: $ha-spacing-mini 0 $ha-spacing-medium (-$ha-spacing-tiny) rgba(0, 0, 0, 0.08),
  $ha-spacing-tiny 0 $ha-spacing-large 0 rgba(0, 0, 0, 0.05),
  $ha-spacing-small 0 $ha-spacing-jumbo $ha-spacing-medium rgba(0, 0, 0, 0.03);

$small-panel-size: $ha-unit * 47.25;
$default-panel-size: $ha-unit * 65.125;
$large-panel-size: $ha-unit * 94.75;
$effect: cubic-bezier(0.23, 1, 0.32, 1);
$time: 0.25s;

.ha-side-panel-animation-enter {
  .ha-side-panel-wrapper-right {
    transform: translateX(100%);
  }

  .ha-side-panel-wrapper-left {
    transform: translateX(-100%);
  }

  .ha-side-panel-wrapper-top {
    transform: translateY(-100%);
  }

  .ha-side-panel-wrapper-bottom {
    transform: translateY(100%);
  }
}

.ha-side-panel-animation-leave-active {
  .ha-side-panel-wrapper-right {
    transform: translateX(100%);
  }

  .ha-side-panel-wrapper-left {
    transform: translateX(-100%);
  }

  .ha-side-panel-wrapper-top {
    transform: translateY(-100%);
  }

  .ha-side-panel-wrapper-bottom {
    transform: translateY(100%);
  }

  .ha-side-panel-mask {
    opacity: 0;
    transition: all $time $effect;
  }
}

.ha-side-panel {
  position: fixed;
  z-index: 900;
  width: 0;
  height: 100%;
  text-align: left;
  opacity: 1;
  transition: all $time $effect;

  &-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background: #000;
    opacity: 0.45;
    pointer-events: none;
  }

  &-wrapper {
    position: absolute;
    width: 100%;
    max-width: 100%;
    height: 100%;
    transition: transform $time $effect;
  }

  &-left,
  &-right {
    top: 0;
    width: 0;
    height: 100%;

    &.ha-side-panel {
      width: 100%;
    }

    .ha-side-panel-wrapper {
      &-small {
        width: $small-panel-size;
      }

      &-default {
        width: $default-panel-size;
      }

      &-large {
        width: $large-panel-size;
      }
    }
  }

  &-left {
    left: 0;

    .ha-side-panel-wrapper {
      left: 0;
      box-shadow: $shadow-right;
    }
  }

  &-right {
    right: 0;

    .ha-side-panel-wrapper {
      right: 0;
      box-shadow: $shadow-left;
    }
  }

  &-top,
  &-bottom {
    left: 0;
    width: 100%;
    height: 0;

    &.ha-side-panel {
      height: 100%;
      transition: transform $time $effect;
    }

    .ha-side-panel-wrapper {
      &-small {
        height: $small-panel-size;
      }

      &-default {
        height: $default-panel-size;
      }

      &-large {
        height: $large-panel-size;
      }
    }
  }

  &-top {
    top: 0;

    .ha-side-panel-wrapper {
      top: 0;
      box-shadow: $shadow-down;
    }
  }

  &-bottom {
    bottom: 0;

    .ha-side-panel-wrapper {
      bottom: 0;
      box-shadow: $shadow-up;
    }
  }

  &.ha-side-panel .ha-side-panel-mask {
    height: 100%;
    pointer-events: auto;
  }

  &-wrapper-content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: white;
    background-clip: padding-box;
    border: 0;
  }

  &-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $ha-spacing-large;
    background: white;
  }

  &-body {
    flex-grow: 1;
    padding: $ha-spacing-large;
    overflow: auto;
    word-wrap: break-word;
  }

  &-footer {
    padding: $ha-spacing-large;
    box-shadow: $ha-box-shadow-middle;
  }
}
</style>
