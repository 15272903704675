<template>
  <div class="search__container">
    <form class="form" @submit.stop.prevent>
      <search-input-container
        ref="searchQuery"
        :label="$t('haComponents.haHeaderPublic.search.labelQuery')"
        placeholder="Une association, une activité..."
        :model="onGoingQuery"
        :can-clear="isQueryToClear"
        input-class="query"
        @on-search="onSearchQuery"
        @clear="clearQuery"
      ></search-input-container>
      <search-location></search-location>
      <ha-button
        id="launchBtnDesktop"
        type="button"
        color="primary"
        class="form__btn form__btn--desktop"
        @click.stop.prevent="launchSearch"
      >
        {{ $t('haComponents.haHeaderPublic.search.btn') }}
        <template #icon>
          <font-awesome-icon class="icon" :icon="faSearch" aria-hidden="true" />
        </template>
      </ha-button>
      <ha-button color="primary" class="form__btn form__btn--mobile" @click="launchSearch">
        {{ $t('haComponents.haHeaderPublic.search.btn') }}
        <template #icon>
          <font-awesome-icon class="icon" :icon="faSearch" aria-hidden="true" />
        </template>
      </ha-button>
    </form>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, computed } from 'vue'
import HaButton from '../../../../HaButton/HaButton.vue'
import useHeaderPublic from '../../../useHeaderPublic'
import SearchLocation from './Partials/SearchLocation/searchLocation'
import SearchInputContainer from './Partials/SearchInputContainer/SearchInputContainer.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSearch } from '@fortawesome/pro-solid-svg-icons'
import useHeaderSearch from '../useHeaderSearch'

const { toggleIsOpenSearch, filtersHeader, routerProject, segment, closeMenuAndSearch } =
  useHeaderPublic()
const { query, setLaunchSearch, onGoingQuery, onSearchQuery, clearQuery } = useHeaderSearch()

const searchQuery = ref(null)

onMounted(() => {
  searchQuery.value.$el.addEventListener('keydown', handleEnter)
  if (window.matchMedia('(min-width: 600px)').matches) {
    searchQuery.value.$el.querySelector('input').focus()
  }
  onGoingQuery.value = query.value
})

onBeforeUnmount(() => {
  searchQuery.value.$el.removeEventListener('keydown', handleEnter)
})
const isQueryToClear = computed(() => Boolean(query.value || onGoingQuery.value))

const launchSearch = () => {
  toggleIsOpenSearch()
  query.value = onGoingQuery.value

  const onGoingFilters = setLaunchSearch(filtersHeader.value)

  const trackInfo = {
    query: onGoingFilters?.query ?? '',
    location: onGoingFilters?.place_city ?? '',
    activity_type: '',
    category_tags: '',
    search_type: 'Query Search'
  }

  segment.value.track('Explore Search', trackInfo)

  const isExplore = window.location.href.includes('/e/')

  if (isExplore) {
    routerProject.value.push({
      path: '/e/recherche',
      query: onGoingFilters
    })
  } else {
    const queryToSearch = new URLSearchParams(onGoingFilters).toString()
    const baseUrl = window.location.origin
    const exploreUrl = `${baseUrl}/e/recherche${!!queryToSearch ? `?${queryToSearch}` : ''}`
    window.location = exploreUrl
  }
}

const handleEnter = e => {
  const isClearInputTarget = e.target.id === 'clearSearchInputButton'
  if (e.code.includes('Enter' || 'NumpadEnter') && !isClearInputTarget) {
    document.getElementById('launchBtnDesktop').click()
  }
  if (e.code === 'Escape') {
    closeMenuAndSearch()
  }
}
</script>

<style lang="scss" scoped>
@import './searchForm.scss';
</style>
