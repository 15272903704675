<script setup>
import { ref, watch } from 'vue'
import HaFormField from '../HaFormField/HaFormField.vue'
import HaDropdown from '../HaDropdown/HaDropdown.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import HaButton from '../HaButton/HaButton.vue'
import getDataError from '../../helpers/getDataError'

const emit = defineEmits(['change', 'input'])
const props = defineProps({
  label: {
    type: String
  },
  buttonLabel: {
    type: String,
    default: ''
  },
  options: {
    type: Array,
    default: () => []
  },
  value: {
    type: Array,
    default: () => []
  },
  disabled: {
    type: Boolean,
    default: false
  },
  isValid: {
    type: Boolean,
    default: null
  }
})

const checkedOptions = ref([])
const dropdown = ref(null)

const emitSelectedOptions = value => {
  emit('input', value)
  emit('change', value)
}

const handleChangeMultiSelect = () => {
  emitSelectedOptions(checkedOptions.value)
}

const handleFocus = () => {
  const elements = dropdown.value.querySelectorAll('input:not([disabled="disabled"])')
  if (elements?.[0]) elements?.[0].focus()
}

const selectAllOptions = () => {
  const selectedOptions = props.options.filter(item => !item.disabled).map(item => item.id)
  emitSelectedOptions(selectedOptions)
}

const removeAllOptions = () => {
  emitSelectedOptions([])
}

watch(
  () => props.value,
  () => {
    checkedOptions.value = props.value
  },
  { immediate: true }
)
</script>

<template>
  <HaFormField
    class="ha-multi-select ha-multi-select--tiers"
    data-test="ha-multi-select"
    :is-valid="isValid"
    :data-error="getDataError(isValid)"
  >
    <template v-if="label" #label>
      {{ label }}
    </template>
    <HaDropdown alignment="left" class="ha-multi-select__dropdown">
      <template #trigger>
        <HaButton
          fluid
          variant="outline"
          color="basic"
          icon-side="right"
          :disabled="disabled"
          @click="handleFocus"
        >
          {{ buttonLabel }}
          <template #icon>
            <FontAwesomeIcon :icon="['fas', 'chevron-down']" />
          </template>
        </HaButton>
      </template>
      <template #content>
        <div class="ha-multi-select__dropdown__buttons">
          <HaButton variant="link" @click="selectAllOptions">
            {{ $t('haComponents.haMultiSelect.selectAll') }}
          </HaButton>
          <div class="ha-multi-select__dropdown__buttons__separator">-</div>
          <HaButton variant="link" @click="removeAllOptions">
            {{ $t('haComponents.haMultiSelect.removeAll') }}
          </HaButton>
        </div>
        <div class="ha-multi-select__dropdown__content" ref="dropdown">
          <div
            v-for="option in options"
            :key="option.id"
            class="ha-multi-select__dropdown__content__checkbox"
          >
            <input
              :id="`checkbox-${option.id}`"
              v-model="checkedOptions"
              type="checkbox"
              :value="option.id"
              :disabled="option.disabled"
              data-test="ha-multi-select-checkbox"
              @change="handleChangeMultiSelect"
            />
            <label :for="`checkbox-${option.id}`">
              <span class="ha-multi-select__dropdown__content__checkbox__icon">
                <FontAwesomeIcon :icon="['fas', 'check']" />
              </span>
              {{ option.label }}
              <span
                v-if="option.status"
                class="ha-multi-select__dropdown__content__checkbox__status"
              >
                - {{ option.status }}
              </span>
            </label>
          </div>
        </div>
      </template>
    </HaDropdown>
  </HaFormField>
</template>

<style scoped lang="scss">
@import './HaMultiSelect';
</style>
