<template>
  <a :href="to" class="link link__arrow" @click.prevent="handleClick">
    {{ text }}
    <font-awesome-icon class="link link__arrowIcon" :icon="faArrowRight" aria-hidden="true" />
  </a>
</template>

<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons'
const props = defineProps({
  to: String,
  text: String
})

const emit = defineEmits(['custom-click'])

const handleClick = () => {
  emit('custom-click')
}
</script>

<style scoped lang="scss">
@import './seolink.scss';
</style>
