<script setup>
import { computed } from 'vue'
import HaTile from '../HaTile/HaTile.vue'

/* values correspond to those of the scss */
const TAB_WIDTH = 160
const TAB_CORNER_WIDTH = 20
const COVER_GAP = 3

const props = defineProps({
  tabs: {
    type: Array,
    required: true
  },
  selectedIndex: {
    type: Number,
    required: true
  },
  title: String
})

const emit = defineEmits(['update:selected-index'])

const selectTab = (index, e) => {
  e.preventDefault()
  e.target.scrollIntoView({ behavior: 'smooth', inline: 'center' })
  emit('update:selected-index', index)
}

const isSelected = index => index === props.selectedIndex

const hasTabsWithSpecialSizes = computed(() => props.tabs.find(t => !!t.size))

const hasSpecialSize = index => {
  return !!props.tabs[index]?.size
}

const defaultPositionCoverBottom = computed(() => {
  return `${COVER_GAP + (TAB_WIDTH + TAB_CORNER_WIDTH / 2) * props.selectedIndex}px`
})

const positionCoverBottom = computed(() => {
  if (!hasTabsWithSpecialSizes.value) return defaultPositionCoverBottom.value

  let gap = COVER_GAP
  for (let i = 1; i <= props.selectedIndex; i++) {
    const tabWidth = hasSpecialSize(i - 1) ? props.tabs[i - 1].size : TAB_WIDTH
    gap += tabWidth + TAB_CORNER_WIDTH / 2
  }

  return `${gap}px`
})

const widthCoverBottom = computed(() => {
  const tabWidth = hasSpecialSize(props.selectedIndex)
    ? props.tabs[props.selectedIndex].size
    : TAB_WIDTH

  return `${tabWidth + TAB_CORNER_WIDTH / 2 + COVER_GAP}px`
})

const tabStyle = index => {
  let style = { zIndex: props.tabs.length - index }
  if (hasSpecialSize(index)) style = { ...style, width: `${props.tabs[index].size}px` }
  return style
}
</script>

<template>
  <HaTile class="folder-tabs" data-test="ha-folder-tabs">
    <h2 v-if="title" class="folder-tabs__title">{{ title }}</h2>
    <div class="folder-tabs__nav-scroller">
      <div class="folder-tabs__nav-cover">
        <nav role="tablist" class="folder-tabs__nav">
          <button
            v-for="(tab, index) in tabs"
            :key="index"
            :id="tab.randomId"
            class="folder-tabs__nav__tab"
            :class="{
              'folder-tabs__nav__tab--selected': selectedIndex === index,
              'folder-tabs__nav__tab--highlighted': tab.highlighted
            }"
            :style="tabStyle(index)"
            role="tab"
            :aria-selected="isSelected(index)"
            :aria-label="tab.text"
            data-test="ha-folder-tabs-item"
            @click="selectTab(index, $event)"
          >
            <span
              class="folder-tabs__nav__tab__text"
              :class="{
                'folder-tabs__nav__tab__text--highlighted': tab.highlighted
              }"
            >
              {{ tab.text }}
            </span>
          </button>
        </nav>
      </div>
    </div>
  </HaTile>
</template>

<style scoped lang="scss">
$tab-height: 40px;
$tab-width: 160px;
$tab-corner-width: 20px;
$cover-height: 10px;
$cover-gap: 3px;

.folder-tabs {
  position: relative;
  width: 100%;
  border-bottom: $ha-border-regular;

  &__title {
    margin-bottom: $ha-spacing-big;
    font-size: 1.5rem;

    @include mediaQuery(600) {
      margin-bottom: $ha-spacing-jumbo;
    }
  }

  &__nav-cover {
    position: relative;
    width: fit-content;
    margin-left: $ha-spacing-large;

    &::after {
      position: absolute;
      bottom: -#{$cover-height};
      left: v-bind(positionCoverBottom);
      z-index: 10;
      width: v-bind(widthCoverBottom);
      height: $cover-height;
      background: var(--ha-color-background);
      content: '';
    }
  }

  &__nav-scroller {
    position: absolute;
    right: 0;
    bottom: -#{$cover-height};
    left: 0;
    height: calc($cover-height + $tab-height);
    padding-bottom: $cover-height;
    overflow-x: auto;
    overflow-y: visible;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__nav {
    display: inline-flex;
    flex-flow: row nowrap;
    padding-right: calc($tab-corner-width / 2);
    overflow: hidden;

    &__tab {
      position: relative;
      width: $tab-width;
      height: $tab-height;
      margin-right: calc($tab-corner-width / 2);
      text-align: center;
      text-decoration: none;
      background: var(--ha-color-white);
      border: $ha-border-regular;
      border-bottom: none;
      border-radius: $ha-spacing-mini $ha-spacing-mini 0 0;
      cursor: pointer;

      &::before {
        position: absolute;
        top: 0.5px;
        right: calc(($tab-corner-width * -1) / 2 - 1px);
        width: $tab-corner-width;
        height: calc($cover-height + $tab-height);
        background: var(--ha-color-white);
        border-right: $ha-border-regular;
        border-radius: $ha-spacing-mini;
        transform: rotate(-25deg);
        content: '';
      }

      &--selected {
        z-index: 10 !important;
        font-weight: $ha-weight-bold;
        background: var(--ha-color-background);
        border-left: solid 3px var(--ha-color-primary);
        box-shadow: 0 3px 3px 0 #0000000d inset;

        &::before {
          background: linear-gradient(
            180deg,
            rgba(247, 249, 253, 0) 0%,
            rgba(247, 249, 253, 0) 5%,
            rgba(247, 249, 253, 1) 10%,
            rgba(247, 249, 253, 1) 100%
          );
        }
      }

      &__text {
        color: var(--ha-color-text);
        text-align: left;
        pointer-events: none;

        &--highlighted::after {
          position: absolute;
          top: 50%;
          width: 8px;
          height: 8px;
          margin-left: 8px;
          background: var(--ha-color-info);
          border-radius: 50%;
          transform: translateY(-50%);
          content: '';
        }
      }
    }
  }
}
</style>
