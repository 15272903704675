<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCrosshairs } from '@fortawesome/pro-solid-svg-icons'
import HaDropdown from '../../../../../../HaDropdown/HaDropdown.vue'
import HaLoader from '../../../../../../HaLoader/HaLoader.vue'
import SearchItem from '../SearchItem.vue'
import SearchInputContainer from '../SearchInputContainer/SearchInputContainer'
import useHeaderSearch from '../../../useHeaderSearch'

const {
  hasSuggestions,
  suggestions,
  setSuggestions,
  city,
  hasHistory,
  history,
  reverseGeoLoc,
  onGoingSelectedLocation,
  isNotEmptyObject,
  isCityCleared,
  onGoingSearchLocation,
  onSearchLocation
} = useHeaderSearch()

const startCity = ref('')
const isSearchingForPosition = ref(false)
const keepDropdownOpen = ref(false)
const selectedSuggestionIndex = ref(-1)
const searchLocationRef = ref(null)

const isCityToClear = computed(
  () => !!isNotEmptyObject(onGoingSelectedLocation.value) || !!onGoingSearchLocation.value
)
const isSelectedCity = computed(() => isNotEmptyObject(onGoingSelectedLocation.value))
const isQuery = computed(() => !!onGoingSearchLocation.value.length)
const isEditing = computed(() => city.value !== onGoingSearchLocation.value)
const totalSuggestions = computed(() => {
  if (!isQuery.value) {
    // check if locateMe btn is here
    return suggestions.value.length + history.value.length + 1
  }
  return suggestions.value.length + history.value.length
})

onMounted(() => {
  startCity.value = city.value // to keep/set this value if a search had been made and the following is dropped
  onGoingSearchLocation.value = city.value
  searchLocationRef.value.$el.addEventListener('keydown', handleKeyboard)
})

onBeforeUnmount(() => {
  setSuggestions([])
  onGoingSelectedLocation.value = null
  searchLocationRef.value.$el.removeEventListener('keydown', handleKeyboard)
})

const handleInput = () => {
  keepDropdownOpen.value = true
}
// selected location can be an object or a number if the search is made with arrow's key
const selectLocation = selectedCity => {
  onGoingSearchLocation.value = selectedCity.place_city
  onGoingSelectedLocation.value = selectedCity
  searchLocationRef.value.closeDropdown() // when migrate on vue 3, need to use defineExpose

  document.getElementById('launchBtnDesktop')?.focus()

  selectedSuggestionIndex.value = -1
}

const onCloseDropdown = () => {
  city.value = startCity.value // to avoid to loose the city if a search had been made
}

const buildItem = (zipcode, department, city) => {
  return {
    zipcode: zipcode,
    place_department: department,
    place_city: city,
    country: 'France'
  }
}
const highlight = words => {
  const regex = new RegExp(
    onGoingSearchLocation.value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'),
    'ig'
  )
  const match = words.search(regex) !== -1
  return !match ? words : words.replace(regex, `<b>${onGoingSearchLocation.value}</b>`)
}

const locateMe = () => {
  let location = null

  navigator.geolocation.getCurrentPosition(async position => {
    isSearchingForPosition.value = true
    location = await reverseGeoLoc(position.coords)

    city.value = location.city || ''
    if (location) {
      const cleanLocation = {
        place_city: location.city,
        place_department: location.context.split(', ')[1],
        zipcode: location.postcode
      }
      await selectLocation(cleanLocation)
      isSearchingForPosition.value = false
    }
  })
  // need to reset for keyboard's navigation
  selectedSuggestionIndex.value = -1
}

const clear = () => {
  onGoingSelectedLocation.value = null
  onGoingSearchLocation.value = ''
  setSuggestions([])
  isCityCleared.value = true
}

const handleKeyboard = e => {
  const arrowKeys = ['ArrowDown', 'ArrowUp', 'Enter', 'NumpadEnter']
  if (arrowKeys.includes(e.code)) {
    e.preventDefault()
    e.stopPropagation()
  }
  const selectableButtons = [...document.querySelectorAll('.result__item__btn')]

  const isNoSearch = computed(() => {
    return (
      !isSelectedCity.value &&
      !onGoingSearchLocation.value.length &&
      selectedSuggestionIndex.value === -1
    )
  })

  const isAutoSearch = computed(() => {
    return (
      !isSelectedCity.value &&
      onGoingSearchLocation.value.length > 2 &&
      hasSuggestions.value &&
      selectedSuggestionIndex.value === -1
    )
  })

  switch (e.code) {
    case 'ArrowDown':
      if (selectedSuggestionIndex.value >= totalSuggestions.value - 1) {
        selectedSuggestionIndex.value = 0
      } else {
        selectedSuggestionIndex.value++
      }
      selectableButtons[selectedSuggestionIndex.value].focus()
      break
    case 'ArrowUp':
      if (selectedSuggestionIndex.value <= 0) {
        selectedSuggestionIndex.value = totalSuggestions.value - 1
      } else {
        selectedSuggestionIndex.value--
      }
      selectableButtons[selectedSuggestionIndex.value].focus()
      break
    case 'Enter':
    case 'NumpadEnter':
      const isClearInputTarget = e.target.id === 'clearSearchInputButton'
      if (isAutoSearch.value && !isClearInputTarget) {
        onGoingSelectedLocation.value = {
          place_city: suggestions.value[0].place_city,
          place_department: suggestions.value[0].place_department,
          zipcode: suggestions.value[0].zipcode
        }
        city.value = onGoingSearchLocation.value
        document.getElementById('launchBtnDesktop').click()
      }
      if (isNoSearch.value) {
        document.getElementById('launchBtnDesktop').click()
      }
      e.target.click()
      break
  }
}
</script>

<template>
  <ha-dropdown ref="searchLocationRef" @close="onCloseDropdown" :keep-open="keepDropdownOpen">
    <template #trigger>
      <search-input-container
        :label="$t('haComponents.haHeaderPublic.search.labelLocation')"
        :model="onGoingSearchLocation"
        placeholder="Une ville, une adresse..."
        input-class="location"
        :can-clear="isCityToClear"
        @on-search="onSearchLocation"
        @handle-input="handleInput"
        @clear="clear"
      >
      </search-input-container>
    </template>
    <template #content>
      <div class="location">
        <client-only>
          <button
            v-if="!isQuery"
            class="location__user result__item__btn"
            :disabled="isSearchingForPosition"
            @click.prevent="locateMe()"
          >
            <ha-loader v-if="isSearchingForPosition" />
            <font-awesome-icon v-else :icon="faCrosshairs" fixed-width aria-hidden="true" />
            {{ $t('haComponents.haHeaderPublic.search.usePosition') }}
          </button>
        </client-only>
        <ul v-if="hasSuggestions" class="location__list">
          <search-item
            v-for="suggestion in suggestions"
            :key="suggestion.id"
            icon="location"
            :title="highlight(suggestion.place_city)"
            :item="
              buildItem(suggestion.zipcode, suggestion.place_department, suggestion.place_city)
            "
            @select-result="selectLocation"
          ></search-item>
        </ul>
        <p v-else-if="!hasSuggestions && isEditing" class="location__noResult">
          {{ $t('haComponents.haHeaderPublic.search.noResults') }}
        </p>
        <div class="history" v-if="hasHistory">
          <span class="history__title">Récentes</span>
          <ul class="location__list">
            <search-item
              v-for="(location, index) in history"
              :key="index"
              icon="history"
              :title="location.place_city"
              :item="buildItem(location.zipcode, location.place_department, location.place_city)"
              @select-result="selectLocation"
            ></search-item>
          </ul>
        </div>
      </div>
    </template>
  </ha-dropdown>
</template>

<style lang="scss">
// do not scoped bc we need to override some HaDropdown classes
@import './searchLocation.scss';
</style>
