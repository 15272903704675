<script setup>
import useHeaderPublic from '../../useHeaderPublic'

const { segment } = useHeaderPublic()

const trackCall = (e) => {
  e.preventDefault()
  const trackInfo = {
    item_type: 'Logo',
    item_label : 'Logo',
    href: '/'
  }

  if (segment.value) {
    segment.value.track('Header Item Clicked', trackInfo)
  }

  window.location = '/'
}
</script>

<template>
  <a
    href="/"
    class="logo"
    @click.prevent="trackCall"
  >
    <span class="visually-hidden">
      Page d'accueil HelloAsso
    </span>
    <img
      src="https://cdn.helloasso.com/images/header_public/logo-helloasso1.svg"
      alt="Accueil Helloasso"
      class="logo__image logo__image--full"
      data-test="ha-logo"
    >
    <img
      src="https://cdn.helloasso.com/images/header_public/badge-helloasso.svg"
      alt="Accueil Helloasso"
      class="logo__image logo__image--mini"
      data-test="ha-logo"
    >
  </a>
</template>

<style lang='scss' scoped>
@import './logo.scss';
</style>
