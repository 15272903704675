<script setup>
import { activities, categories } from './../../../constants'
import SeoLink from './../../SeoLink/SeoLink.vue'
import useHeaderPublic from '../../../useHeaderPublic'

const { segment } = useHeaderPublic()

const getImagePath = (img) => {
  const cdnUrl = "https://cdn.helloasso.com/images/header_public/"
  return `${cdnUrl}${img}`
}

const trackCall = (type, name, slug) => {
  const trackInfo = {
    query: '',
    location: '',
    activity_type: type === 'activity' ? name : '',
    category_tags: type === 'category' ? name : '',
    search_type: 'Suggestion'
  }

  if (segment.value) {
    segment.value.track('Explore Search', trackInfo)
  }

  const href = type === 'activity' ? `/e/recherche?tab=activites&activity_type=${slug}` : `/e/recherche?tab=associations&category_tags=${slug}`

  window.location = href
}
</script>

<template>
  <section class="suggestion">
    <h4 class="article__title">{{ $t('haComponents.haHeaderPublic.search.suggestionActivities') }}</h4>
    <article class="article suggestion__activities">
      <a
        v-for="(activity, index) in activities"
        :key="index"
        :href="`/e/recherche?tab=activites&activity_type=${activity.slug}`"
        class="article__link"
        @click.prevent="trackCall('activity', activity.name, activity.slug)"
      >
        <figure class="article__figure">
          <img :src="getImagePath(activity.img)" alt="" class="article__image">
          <figcaption class="article__caption">{{ activity.name }}</figcaption>
        </figure>
      </a>
    </article>
      <h4 class="article__title">{{ $t('haComponents.haHeaderPublic.search.suggestionCategories') }}</h4>
    <article class="article suggestion__categories">
      <div class="article__content">
        <SeoLink
          v-for="(category) in categories"
          :key="category.name"
          :to="`/e/recherche?tab=associations&category_tags=${category.slug}`"
          :text="category.name"
          @custom-click="trackCall('category', category.name, category.slug)">
        </SeoLink>
      </div>
    </article>
  </section>
</template>

<style lang="scss" scoped>
@import './searchSuggestion.scss';
</style>
